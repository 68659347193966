import React from 'react';
import appStore from '../stores/appStore';

const textOrInput = (selected, text, updateEntity) => {
	if (selected) {
		return (
			<input
				onFocus={() => {
					appStore.disableKeyboardShortcuts = true;
				}}
				onBlur={() => {
					appStore.disableKeyboardShortcuts = false;
				}}
				type="text"
				defaultValue={text}
				onChange={(e) => {
					updateEntity({ text: e.target.value });
				}}
			></input>
		);
	} else {
		return <span>{text}</span>;
	}
};

const InnerEntity = ({
	id,
	x,
	y,
	width,
	height,
	text,
	selectEntity,
	selected,
	updateEntity,
}) => (
	<div
		key={id}
		className={selected ? 'entity-text selected' : 'entity-text'}
		style={{
			top: y,
			left: x,
			width,
			height,
		}}
		onClick={selectEntity(id)}
	>
		{textOrInput(selected, text, updateEntity)}
	</div>
);

export default InnerEntity;
