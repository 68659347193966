const calculateControlPoint = ({
	to,
	fromEntity,
	toEntity,
	fromCoords,
	toCoords,
}) => {
	const xDiff = toEntity.x - fromEntity.x;
	const yDiff = toEntity.y - fromEntity.y;

	let cpx = 0;
	let cpy = 0;

	if (xDiff > 0 && yDiff > 0) {
		// from left to right, top to bottom
		cpx = to.side === 'top' ? toCoords.x : fromCoords.x;
		cpy = to.side === 'top' ? fromCoords.y : toCoords.y;
	} else if (xDiff > 0 && yDiff < 0) {
		// from left to right, bottom to top
		cpx = to.side === 'bottom' ? toCoords.x : fromCoords.x;
		cpy = to.side === 'bottom' ? fromCoords.y : toCoords.y;
	} else if (xDiff < 0 && yDiff > 0) {
		// from right to left, top to bottom
		cpx = to.side === 'top' ? toCoords.x : fromCoords.x;
		cpy = to.side === 'top' ? fromCoords.y : toCoords.y;
	} else if (xDiff < 0 && yDiff < 0) {
		// from right to left, bottom to top
		cpx = to.side === 'bottom' ? toCoords.x : fromCoords.x;
		cpy = to.side === 'bottom' ? fromCoords.y : toCoords.y;
	}

	const controlPoint = {
		x: cpx,
		y: cpy,
	};
	return controlPoint;
};

const getPoints = ({ fromCoords, controlPoint, toCoords }) => {
	const points = [
		'M',
		fromCoords.x,
		fromCoords.y,
		controlPoint ? 'Q' : null,
		controlPoint?.x,
		controlPoint?.y,
		toCoords.x,
		toCoords.y,
	]
		.filter(Boolean)
		.join(' ');

	return points;
};

const calculateRelationshipPoint = ({ fromCoords, toCoords, controlPoint }) => {
	const x = (toCoords.x + fromCoords.x + controlPoint.x) / 3;
	const y = (toCoords.y + fromCoords.y + controlPoint.y) / 3;
	return { x, y };
};

export { calculateControlPoint, getPoints, calculateRelationshipPoint };
