import React from 'react';
import { calculateCoords } from '../helpers/calculateCircleCoords';
import { drawTriangle } from '../helpers/triangle';
import {
	calculateControlPoint,
	getPoints,
	calculateRelationshipPoint,
} from '../helpers/pointsHelpers';
import relationshipStore from '../stores/relationshipStore';
import entityStore from '../stores/entityStore';
import loopStore from '../stores/loopStore';

const selectRelationship = (id) => {
	return (event) => {
		relationshipStore.select(id);
		event.stopPropagation();
	};
};

const toggleRelationshipType = (id) => {
	return function () {
		relationshipStore.toggleType(id);
		loopStore.detectLoops(relationshipStore.relationships);
	};
};

// NOTE - it would be nice to decouple this in some way, so that it doesn't
// depend on the entities being fetched from outside the component.
const Relationship = (relationship) => {
	const { id, from, to, type } = relationship;
	const fromEntity = entityStore.entities.find((e) => e.id === from.entityId);
	const toEntity = entityStore.entities.find((e) => e.id === to.entityId);
	const fromCoords = calculateCoords(fromEntity, from.side);
	const toCoords = calculateCoords(toEntity, to.side);
	const controlPoint = calculateControlPoint({
		to,
		fromEntity,
		toEntity,
		fromCoords,
		toCoords,
	});
	const points = getPoints({ fromCoords, controlPoint, toCoords });
	const relationshipPoint = calculateRelationshipPoint({
		fromCoords,
		toCoords,
		controlPoint,
	});

	return (
		<g
			className={
				relationshipStore.selected === id
					? 'relationship selected'
					: 'relationship'
			}
		>
			<path
				id={id}
				d={points}
				stroke="black"
				fill="none"
				onClick={selectRelationship(id)}
			/>
			<rect
				className="debug"
				x={controlPoint.x - 2.5}
				y={controlPoint.y - 2.5}
				width="5"
				height="5"
			/>
			<g
				className={'relationship-type ' + type}
				onClick={toggleRelationshipType(id)}
			>
				<circle
					cx={relationshipPoint.x}
					cy={relationshipPoint.y}
					r="10"
					stroke="grey"
				></circle>
				<text
					x={relationshipPoint.x}
					y={relationshipPoint.y + 4}
					textAnchor="middle"
				>
					{type === 'positive' ? '+' : '-'}
				</text>
			</g>
			{toCoords && drawTriangle(
				toCoords.x,
				toCoords.y,
				10,
				10,
				to.side,
				relationshipStore.selected === id
			)}
		</g>
	);
};

export default Relationship;
