import React from 'react';
import entityStore from '../stores/entityStore';
import { getPointsForTriangle } from '../helpers/triangle';

const calculateCenter = (entities) => {
	const leftPoint = Math.min(...entities.map((e) => e.x));
	const rightPoint = Math.max(...entities.map((e) => e.x + e.width));
	const topPoint = Math.min(...entities.map((e) => e.y));
	const bottomPoint = Math.max(...entities.map((e) => e.y + e.height));
	const x = (leftPoint + rightPoint) / 2;
	const y = (topPoint + bottomPoint) / 2;
	return { x, y };
};

const Loop = (loop) => {
	const typeText = loop.type === 'reinforcing' ? 'R' : 'B';
	const entities = entityStore.entities.filter((e) =>
		loop.entities.includes(e.id)
	);
	const { x, y } = calculateCenter(entities);

	const trianglePoints = getPointsForTriangle(x - 20, y - 6, 10, 10, 'bottom')
		.map((point) => {
			return `${point.x},${point.y}`;
		})
		.join(' ');
	return (
		<g x={x} y={y} className={'loop ' + loop.type}>
			<circle cx={x} cy={y} r="20"></circle>
			<text x={x - 5} y={y + 5}>
				{typeText}
			</text>
			<polygon points={trianglePoints}></polygon>
		</g>
	);
};

export default Loop;
