import { useEffect } from 'react';
import * as d3 from 'd3';

let zoom;
let canvas;

export const resetZoomAndCenter = () => {
	const { zoomIdentity } = d3;
	canvas.transition().duration(300).call(zoom.transform, zoomIdentity);
};

const usePanAndZoom = (appStore) => {
	const trackMousePosition = (e) => {
		appStore.update('mousePosition', { x: e.clientX, y: e.clientY });
	};

	return useEffect(
		() => {
			// Only runs on client-side
			if (window) {
				canvas = d3.select('svg#canvas');
				zoom = d3.zoom();
				canvas
					.call(
						zoom.on('zoom', (event) => {
							const zoomTransform = event.transform;
							const { k, x, y } = zoomTransform;
							appStore.update('transform', {
								scale: k,
								translateX: x,
								translateY: y,
								svg: `translate(${x}, ${y}) scale(${k})`,
								html: `matrix(${k}, 0, 0, ${k}, ${x}, ${y})`,
							});
						})
					)
					// Disables double-click zoom, so double-clicking to add an entity works
					.on('dblclick.zoom', null);

				// Used to track the mouse position for creating new entities at the mouse position
				window.onmousemove = trackMousePosition;
			}
			// Unmount function returned here 
			return () => {
				if (window?.onmousemove) {
					window.removeEventListener('mousemove', window.onmousemove);
				}
			}
		},
		[]
	);
};

export default usePanAndZoom;
