import React from 'react';
import * as d3 from 'd3';
import useStores from '../helpers/useStores';
import { v4 as uuidv4 } from 'uuid';

const Entity = ({ id, x, y, width, height, selectEntity, selected }) => {
	const { relationshipStore } = useStores();

	const trackEnter = (e) => {
		d3.select(e.target).classed('active', true);
		relationshipStore.to = {
			entityId: e.target.parentNode.id.replace('group-', ''),
			side: e.target.attributes['data-side'].value,
		};
		const { from, to } = relationshipStore;
		const type = 'positive';
		if (from && to && from.entityId !== to.entityId) {
			const id = uuidv4();
			relationshipStore.add({ id, from, to, type });
		}
	};

	const trackLeave = (e) => {
		relationshipStore.to = null;
		d3.select(e.target).classed('active', false);
	};

	const r = 10;
	const top = y;
	const bottom = y + height;
	const left = x;
	const right = x + width;
	const horizontalCenter = x + width / 2;
	const verticalCenter = y + height / 2;
	const circles = [
		{ cx: horizontalCenter, cy: top, r, 'data-side': 'top' },
		{
			cx: horizontalCenter,
			cy: bottom,
			r,
			'data-side': 'bottom',
		},
		{ cx: left, cy: verticalCenter, r, 'data-side': 'left' },
		{
			cx: right,
			cy: verticalCenter,
			r,
			'data-side': 'right',
		},
	];
	return (
		<g id={'group-' + id} key={'group' + id}>
			{circles.map((circle, index) => (
				<circle
					key={'circle-' + id + '-' + index}
					{...circle}
					onMouseEnter={trackEnter}
					onMouseLeave={trackLeave}
				/>
			))}
			<rect
				id={id}
				key={'rect' + id}
				onClick={selectEntity(id)}
				className={selected ? 'element selected' : 'element'}
				x={x}
				y={y}
				width={width}
				height={height}
				rx="25"
				ry="25"
			></rect>
		</g>
	);
};

export default Entity;
