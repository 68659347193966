import React from 'react';
// This will eventually be used for buttons that allow dragging and dropping elements onto the canvas

const ActionBarButton = ({ label, func }) => (
	<div key={label} className="action-bar-button" onClick={func}>
		{label}
	</div>
);

const ActionBar = ({ resetZoomAndCenter, save, load }) => {
	const actions = [
		{ label: 'Reset Zoom & Center', func: resetZoomAndCenter },
		{ label: 'Save', func: save },
		{ label: 'Load', func: load },
	];

	return <div id="action-bar">{actions.map(ActionBarButton)}</div>;
};

export default ActionBar;
