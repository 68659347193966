const calculateCoords = (entity, side) => {
	if (entity) {
		const { x, y, width, height } = entity;
		switch (side) {
			case 'left':
				return {
					x: x,
					y: y + height / 2,
				};
			case 'right':
				return {
					x: x + width,
					y: y + height / 2,
				};
			case 'top':
				return {
					x: x + width / 2,
					y: y,
				};
			case 'bottom':
				return {
					x: x + width / 2,
					y: y + height,
				};
			default:
				return {
					x: x,
					y: y,
				};
		}
	} else {
		console.log('NO ENTITY FOR SIDE', side);
	}
};

export { calculateCoords };
