import React from 'react';
import { getPoints } from '../helpers/pointsHelpers';

const calculateControlPointForDrawnPath = ({
	dragPositionStart,
	dragPositionEnd,
	circleSide,
}) => {
	const xDiff = dragPositionEnd.x - dragPositionStart.x;
	const yDiff = dragPositionEnd.y - dragPositionStart.y;

	let cpx = 0;
	let cpy = 0;

	if (xDiff > 0 && yDiff > 0) {
		// from left to right, top to bottom
		if (circleSide === 'right') {
			cpx = dragPositionEnd.x;
			cpy = dragPositionStart.y;
		} else {
			cpx = dragPositionStart.x;
			cpy = dragPositionEnd.y;
		}
	} else if (xDiff > 0 && yDiff < 0) {
		// from left to right, bottom to top
		if (circleSide === 'top') {
			cpx = dragPositionStart.x;
			cpy = dragPositionEnd.y;
		} else {
			cpx = dragPositionEnd.x;
			cpy = dragPositionStart.y;
		}
	} else if (xDiff < 0 && yDiff > 0) {
		// from right to left, top to bottom
		if (circleSide === 'bottom') {
			cpx = dragPositionStart.x;
			cpy = dragPositionEnd.y;
		} else {
			cpx = dragPositionEnd.x;
			cpy = dragPositionStart.y;
		}
	} else if (xDiff < 0 && yDiff < 0) {
		// from right to left, bottom to top
		if (circleSide === 'left') {
			cpx = dragPositionEnd.x;
			cpy = dragPositionStart.y;
		} else {
			cpx = dragPositionStart.x;
			cpy = dragPositionEnd.y;
		}
	}

	const controlPoint = {
		x: cpx,
		y: cpy,
	};
	return controlPoint;
};

const DrawPath = ({
	isCircleDragging,
	dragPositionStart,
	dragPositionEnd,
	circleSide,
}) => {
	if (!isCircleDragging) return null;
	if (dragPositionEnd.x === 0 && dragPositionEnd.y === 0) return null;

	const controlPoint = calculateControlPointForDrawnPath({
		dragPositionStart,
		dragPositionEnd,
		circleSide,
	});

	const points = getPoints({
		fromCoords: dragPositionStart,
		controlPoint: controlPoint,
		toCoords: dragPositionEnd,
	});

	return (
		<path
			className="relationship"
			d={points}
			stroke="black"
			strokeDasharray="5,5"
			fill="none"
		/>
	);
};

export default DrawPath;
