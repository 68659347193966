// Dependencies
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import useStores from '../src/helpers/useStores';
import useKeyPress from '../src/helpers/useKeyPress';
import usePanAndZoom, {
	resetZoomAndCenter,
} from '../src/helpers/usePanAndZoom';
import Canvas from '../src/components/Canvas';
import ActionBar from '../src/components/ActionBar';

const HomePage = () => {
	const { entityStore, appStore, relationshipStore } = useStores();
	const { entities } = entityStore;
	const { relationships } = relationshipStore;

	// Applies panning and zooming, plus tracks the mouse position
	usePanAndZoom(appStore);

	const selectEntity = (id) => {
		return (e) => {
			if (e) e.stopPropagation();
			entityStore.select(id);
		};
	};

	function dragstarted(d) {
		if (appStore.mode === 'entity') {
			entityStore.select(this.id);
			d3.select(this).raise().classed('active', true);
		} else {
			const { id } = this;
			relationshipStore.from = {
				entityId: id,
				side: 'top', // we'll say top for now
			};
			appStore.update('circleDragging', true);
			appStore.update(
				'dragPositionStart',
				appStore.calculateXYCoords(d.subject.x, d.subject.y)
			);
		}
	}

	function dragging(d) {
		if (appStore.mode === 'entity') {
			const { id } = this;
			const entity = entityStore.get(id);
			const x = d.x - entity.width / 2;
			const y = d.y - entity.height / 2;
			entityStore.updateCoordinates(id, x, y);
		} else {
			appStore.update(
				'dragPositionEnd',
				appStore.calculateXYCoords(d.x, d.y)
			);
		}
	}

	function dragended(d) {
		if (appStore.mode === 'entity') {
			d3.select(this).classed('active', false);
		} else {
			appStore.update('circleDragging', false);
			d3.select(this).classed('active', false);
		}
	}

	function circleDragStarted(d) {
		const circle = d3.select(this);
		circle.classed('active', true);
		relationshipStore.from = {
			entityId: circle.node().parentNode.id.replace('group-', ''),
			side: circle.attr('data-side'),
		};
		appStore.update('isCircleDragging', true);
		appStore.update('circleSide', circle.attr('data-side'));
		appStore.update('dragPositionStart', {
			x: d.subject.x,
			y: d.subject.y,
		});
	}

	function circleDragged(d) {
		d3.select(this).classed('active', true);
		appStore.update('dragPositionEnd', { x: d.x, y: d.y });
	}

	function circleDragEnded(d) {
		appStore.update('isCircleDragging', false);
		appStore.update('circleSide', null);
		appStore.update('dragPositionStart', { x: 0, y: 0 });
		appStore.update('dragPositionEnd', { x: 0, y: 0 });
		d3.select(this).classed('active', false);
	}

	const addEntity = ({ clientX, clientY }) => {
		const id = uuidv4();
		const width = 200; // This should be adjusted for when the text is edited
		const height = 50;
		const x = clientX - width / 2;
		const y = clientY - height / 2;
		const text = 'Entity'; // The default text for the entity at the start
		const entity = {
			id,
			x,
			y,
			width,
			height,
			text,
		};
		entityStore.add(entity);
	};

	const removeEntity = (id?:string) => {
		if (!id) id = entityStore.selected;
		if (!id) return;
		const linkedRelationships = relationshipStore.relationships.filter(
			(r) => r.from.entityId === id || r.to.entityId === id
		);
		linkedRelationships.forEach((r) => relationshipStore.remove(r.id));
		entityStore.remove(id);
		entityStore.deselect();
	};

	const removeRelationship = (id?:string) => {
		if (!id) id = relationshipStore.selected;
		if (!id) return;
		relationshipStore.remove(id);
		relationshipStore.deselect();
	};

	// Binds backspace event to deleting the selected entity
	// NOTE - need a way to prevent keypress events being triggered when the user is typing in the text field
	useKeyPress('Backspace', () => {
		// This hook won't pickup updates to the useState value
		if (!appStore.disableKeyboardShortcuts) {
			removeEntity();
			removeRelationship();
		}
	});

	useKeyPress('n', () => {
		if (!appStore.disableKeyboardShortcuts) {
			appStore.update('mode', 'entity');
			const { transform } = appStore;
			// Get mouse coordinates here
			const scale = transform.scale || 1;
			const translateX = transform.translateX || 0;
			const translateY = transform.translateY || 0;
			addEntity({
				clientX: appStore.mousePosition.x / scale - translateX / scale,
				clientY: appStore.mousePosition.y / scale - translateY / scale,
			});
		}
	});

	useKeyPress('r', () => {
		if (!appStore.disableKeyboardShortcuts) {
			appStore.update('mode', 'relationship');
		}
	});

	// This effect is used to bind drag and drop for the nodes
	useEffect(() => {
		d3.selectAll('.element').call(
			d3
				.drag()
				.on('start', dragstarted)
				.on('drag', dragging)
				.on('end', dragended)
		);
	}, [entityStore.entities.length]);

	// NOTE - might need to change the variable that triggers the effect as it does not change right away
	useEffect(() => {
		d3.selectAll('circle').call(
			d3
				.drag()
				.on('start', circleDragStarted)
				.on('drag', circleDragged)
				.on('end', circleDragEnded)
		);
	}, [
		appStore.mousePosition.x,
		entityStore.selected,
		relationshipStore.selected,
	]);

	const save = () => {
		appStore.save({ entities, relationships });
	};

	const load = () => {
		const diagram = appStore.load();
		if (diagram) {
			diagram.entities.map((e) => {
				entityStore.add(e);
			});
			diagram.relationships.map((r) => {
				relationshipStore.add(r);
			});
		}
	};

	// NOTE - it would be nice to not have to use props drilling here
	return (
		<>
			<Canvas
				transform={appStore.transform}
				entities={toJS(entities)}
				relationships={toJS(relationships)}
				selected={entityStore.selected}
				selectEntity={selectEntity}
				isCircleDragging={appStore.isCircleDragging}
				dragPositionStart={appStore.dragPositionStart}
				dragPositionEnd={appStore.dragPositionEnd}
				circleSide={appStore.circleSide}
			/>
			<ActionBar
				resetZoomAndCenter={resetZoomAndCenter}
				save={save}
				load={load}
			/>
		</>
	);
};

export default observer(HomePage);
