import React, { useEffect } from 'react';
import entityStore from '../stores/entityStore';
import Entity from './Entity';
import InnerEntity from './InnerEntity';
import Loop from './Loop';
import Relationship from './Relationship';
import relationshipStore from '../stores/relationshipStore';
import loopStore from '../stores/loopStore';
import DrawPath from './Drawpath';

const Canvas = ({
	transform,
	entities,
	relationships,
	selectEntity,
	selected,
	isCircleDragging,
	dragPositionStart,
	dragPositionEnd,
	circleSide,
}) => {
	const deselect = () => {
		entityStore.deselect();
		relationshipStore.deselect();
	};

	const updateEntity = (props) => {
		props.id = selected;
		entityStore.update(props);
	};

	useEffect(() => {
		loopStore.detectLoops(relationshipStore.relationships);
	}, [relationshipStore.relationships.length, entityStore.entities.length]);

	return (
		<>
			<svg id="canvas" onClick={deselect}>
				<g id="inner-canvas" transform={transform.svg}>
					<DrawPath
						isCircleDragging={isCircleDragging}
						dragPositionStart={dragPositionStart}
						dragPositionEnd={dragPositionEnd}
						circleSide={circleSide}
					/>
					{entities.map((e) => (
						<Entity
							{...e}
							selectEntity={selectEntity}
							selected={e.id === selected}
						/>
					))}
					{relationships.map(Relationship)}
					{loopStore.loops.map(Loop)}
				</g>
			</svg>
			<div id="entities">
				<div id="inner-entities" style={{ transform: transform.html }}>
					{entities.map((e) => (
						<InnerEntity
							{...e}
							selectEntity={selectEntity}
							selected={e.id === selected}
							updateEntity={updateEntity}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default Canvas;
