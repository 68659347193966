import React from 'react';

const triangleForTop = ({ x, y, width, height }) => [
	{ x: x - width / 2, y: y - height },
	{ x: x + width / 2, y: y - height },
	{ x, y },
];

const triangleForBottom = ({ x, y, width, height }) => [
	{ x: x - width / 2, y: y + height },
	{ x: x + width / 2, y: y + height },
	{ x, y },
];

const triangleForLeft = ({ x, y, width, height }) => [
	{ x: x - width, y: y - height / 2 },
	{ x: x - width, y: y + height / 2 },
	{ x, y },
];

const triangleForRight = ({ x, y, width, height }) => [
	{ x: x + width, y: y - height / 2 },
	{ x: x + width, y: y + height / 2 },
	{ x, y },
];

/*
    Calculates the points for a polygon so that a triangle can be drawn

    @param {number} x - The x coordinate of the edge of the node
    @param {number} y - The x coordinate of the edge of the node
    @param {number} width - The width of the triangle
    @param {number} height - The height of the triangle
    @param {string} direction - The direction of the triangle
*/
const getPointsForTriangle = (x, y, width, height, direction) => {
	switch (direction) {
		case 'top':
			return triangleForTop({ x, y, width, height });
		case 'right':
			return triangleForRight({ x, y, width, height });
		case 'bottom':
			return triangleForBottom({ x, y, width, height });
		case 'left':
			return triangleForLeft({ x, y, width, height });
		default:
			return triangleForTop({ x, y, width, height });
	}
};

const drawTriangle = (x, y, width, height, direction, selected) => {
	const pointsList = getPointsForTriangle(x, y, width, height, direction);
	const points = pointsList
		.map((point) => {
			return `${point.x},${point.y}`;
		})
		.join(' ');
	return (
		<polygon
			{...{ points }}
			className={selected ? 'endpoint selected' : 'endpoint'}
		/>
	);
};

export { getPointsForTriangle, drawTriangle };
